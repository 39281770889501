<template>
  <div>
    <el-row>
      <el-col :span="24" class="infoTit">
        <span
          v-if="goodsinfo.otcType === 1 && goodsinfo.drugCategory == 0"
          class="tag bgRed titColor radiusBox"
          >OTC</span
        >
        <span
          v-if="goodsinfo.otcType === 1 && goodsinfo.drugCategory == 1"
          class="tag bgGreen titColor radiusBox"
          >OTC</span
        >
        <span
          v-if="goodsinfo.otcType === 0"
          class="tag bgRed titColor radiusBox"
          >Rx</span
        >
        <span
          v-if="goodsinfo.newStatus == true || goodsinfo.newStatus == 'true'"
          class="tag bgGreen titColor"
          >新品</span
        >
        <span
          v-if="goodsinfo.medicalInsuranceType > 0"
          class="tag bgBlue titColor"
          >医保</span
        >
        <span v-if="goodsinfo.proprietary == 1" class="tag bgOrange titColor"
          >自营</span
        >
        <span class="brandName">{{ goodsinfo.brandName }} </span>
        <!-- {{ goodsinfo.name }} -->
        <span v-if="goodsinfo.alias == '' || goodsinfo.alias == null">{{
          goodsinfo.name
        }}</span>
        <span v-else>{{ goodsinfo.alias }} </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>{{ goodsinfo.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
    </el-row>
    <div class="UlBoxs">
      <span class="liBox">
        <span class="infoKeyPrice">会员价：</span>
        <span
          class="price"
          v-if="goodsinfo.numPrice && goodsinfo.numPrice != ''"
          ><strong>￥</strong
          >{{ goodsinfo.price === "***" ? "会员可见" : priceMsg }}</span
        >
        <span class="price" v-else
          ><strong>￥</strong
          >{{ goodsinfo.price === "***" ? "会员可见" : goodsinfo.price }}</span
        >
        <del v-if="goodsinfo.numPrice && goodsinfo.numPrice != ''">{{
          goodsinfo.price !== "***"
            ? Number(goodsinfo.price) >= Number(goodsinfo.memberPrice)
              ? "￥" + Number(goodsinfo.price)
              : goodsinfo.memberPrice
            : ""
        }}</del>
      </span>
      <el-divider direction="vertical"></el-divider>
      <span class="liBox">
        <span class="infoKey">推荐零售价：</span>
        ￥{{ goodsinfo.price === "***" ? "会员可见" : goodsinfo.originalPrice }}
      </span>
      <el-divider direction="vertical"></el-divider>
      <span class="liBox">
        <span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%
      </span>
      <el-divider direction="vertical"></el-divider>
      <span class="liBox">
        <span class="infoKey">净赚：</span>￥{{
          goodsinfo.price === "***" ? "会员可见" : goodsinfo.earnings
        }}
      </span>
      <!-- <li>
          <span class="infoKeyPrice">会员价：</span>
          <span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
          <del>{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'':goodsinfo.memberPrice:''}}</del>
          <span class="bian"></span>
        </li>
        <li>
          <span class="infoKey">推荐零售价：</span>
          ￥{{goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice}}
        </li>
        <li>
          <span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%
        </li>
        <li>
          <span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}
        </li> -->
    </div>
    <el-row class="infoBox">
      <div v-if="ByGoodsFlag" class="hotProductTagBox" @click="hotProductFun">
        <span class="hotProductTag"
          >{{ ByGoodsTag }}
          <b><img src="@/assets/hotProductTag.png" alt="" /></b
        ></span>
      </div>
      <!-- <el-col :span="6">
        <span class="infoKeyPrice">会员价：</span>
        <span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
         <del>{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'':goodsinfo.memberPrice:''}}</del>
         <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col :span="6">
        <span class="infoKey">推荐零售价：</span>
        ￥{{goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice}}
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col :span="6">
        <span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%
        <el-divider direction="vertical"></el-divider>
      </el-col>
      <el-col :span="6">
        <span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}
      </el-col> -->
      <div
        v-if="promotionMap.length == 1 && promotionMap[0].type === '特价'"
      ></div>
      <div v-else>
        <el-col
          :span="24"
          class="infoActivity"
          v-for="(item, index) in promotionMap"
          :key="index"
        >
          <span class="infoKey" v-if="index == 0">促销活动</span>
          <span class="noMsg" v-else></span>
          <div class="ActivityDesBox">
            <span
              class="infoTag"
              v-if="item.type == '一元购'"
              @click="JumpONE_BUYFun(item.id)"
              >{{ item.type }}</span
            >
            <span
              class="infoTag"
              v-else-if="item.type == '限时拼团'"
              @click="JumpFlashGroupFun(item.id)"
              >{{ item.type }}</span
            >
            <span
              class="infoTag"
              v-else-if="item.type == '超级秒杀'"
              @click="JumpFlashSaleFun(item.id)"
              >{{ item.type }}</span
            >
            <span class="noMsg" v-else-if="item.type == '特价'"></span>
            <span class="infoTag" v-else>{{ item.type }}</span>
            <span class="noMsg" v-if="item.type == '特价'"></span>
            <span v-else class="infoDes">{{ item.name }}</span>
          </div>
        </el-col>
      </div>
      <el-col
        :span="24"
        class="infoActivitys"
        v-if="goodsinfo.numPrice && goodsinfo.numPrice != ''"
      >
        <span v-if="promotionMap.length < 1" class="infoKey">促销活动:</span>
        <span v-else class="infoKey"></span>
        <span class="infoTag">特价活动</span>
        <p
          class="numPriceDes"
          v-for="(item, index) in numPriceArr"
          :key="index"
        >
          满{{ item.num }}{{ goodsinfo.unit }}<span v-if="item.ratio"
            >享{{ item.ratio / 10 }}折,</span
          >
          <span v-if="item.ratio == 0">享特价{{ item.price }}元，</span
          >每{{ goodsinfo.unit }}约省<b>{{
            Number(goodsinfo.memberPrice - item.price).toFixed(2)
          }}</b
          >元
          <!-- <span v-if="index<JSON.parse(goodsinfo.numPrice).length-1">,</span>   -->
        </p>
      </el-col>
    </el-row>
    <el-row :span="24" class="CouponBox" v-if="goodsinfo.coupons.length > 0">
      <div>
        <span class="infoKey">优惠券:</span>
        <span
          class="conponMsg"
          v-for="(item, index) in goodsinfo.coupons.slice(0, 3)"
          :key="index"
          @click="drawer = true"
          type="primary"
          >{{ item.couponName }}</span
        >
      </div>
    </el-row>
    <el-drawer
      title="优惠券"
      :with-header="false"
      :visible.sync="drawer"
      direction="rtl"
      :before-close="handleClose"
    >
      <div class="headTit">优惠券</div>
      <div class="conMain">
        <div>
          <GetCouponItem
            v-for="(item, index) in goodsinfo.coupons"
            :key="index"
            :span="24"
            :couponItem="item"
          ></GetCouponItem>
        </div>
      </div>
    </el-drawer>
    <el-row :span="24" class="infoParameter" v-loading="loading">
      <div>
        <span class="infoKey">商品编号:</span
        ><span>{{ goodsinfo.productSn }}</span>
      </div>
      <div>
        <span class="infoKey">国药准字:</span
        ><span>{{ goodsinfo.approvalNo }}</span>
      </div>
      <div>
        <span class="infoKey">药品规格:</span
        ><span>{{ goodsinfo.specification }}</span>
      </div>
      <div>
        <span class="infoKey">件装规格:</span
        ><span>{{ goodsinfo.partSpecification }}</span>
      </div>
      <div>
        <span class="infoKey">生产日期:</span
        ><span>{{ goodsinfo.producedDateFromat }}</span>
      </div>
      <div>
        <span class="infoKey">效期优于:</span
        ><span>{{ goodsinfo.validDateFromat }}</span>
      </div>
      <div>
        <span class="infoKey">医保类型:</span
        ><span>{{
          goodsinfo.medicalInsuranceType === 0
            ? "非医保"
            : goodsinfo.medicalInsuranceType === 1
            ? "甲类医保"
            : "乙类医保"
        }}</span>
      </div>
      <div v-if="goodsinfo.insuranceCode">
        <span class="infoKey">医保代码:</span
        ><span>{{ goodsinfo.insuranceCode }}</span>
      </div>
      <div v-if="goodsinfo.barCode">
        <span class="infoKey">条形编码:</span
        ><span>{{ goodsinfo.barCode }}</span>
      </div>
      <div>
        <!-- text-indent: 30px;letter-spacing: 17px; -->
        <span class="infoKey" style="text-indent: 10px; letter-spacing: 9px"
          >库存:</span
        >
        <!-- <span>{{goodsinfo.quantity==-1 ||goodsinfo.quantity==0 ?'备货中':goodsinfo.quantity>1000?"充足":goodsinfo.quantity+goodsinfo.unit}}</span> -->
        <span
          v-if="
            goodsinfo.quantity == -1 ||
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
          "
          >备货中</span
        >
        <span v-if="goodsinfo.quantity > 1000">充足</span>
        <span
          v-if="
            goodsinfo.quantity <= 1000 &&
            goodsinfo.quantity >= Number(goodsinfo.minOrderNum)
          "
          >{{ goodsinfo.quantity }}{{ goodsinfo.unit }}</span
        >
      </div>
      <div v-if="goodsinfo.quantity < 1 && goodsinfo.deliveryTimeFormat">
        <span class="infoKey">到货日期:</span
        ><span>{{ goodsinfo.deliveryTimeFormat }}</span>
      </div>
      <div>
        <span class="infoKey">首营资料：</span>
        <strong class="downBtn" @click="showload()">查看详情</strong> &nbsp;
        <strong class="downBtn" @click="Download()">打包下载</strong>
      </div>
    </el-row>

    <el-row
      class="infoActivitysBox"
      v-if="goodsinfo.numPrice && goodsinfo.numPrice != ''"
    >
      <span class="infoKey">特价活动</span>
      <el-col :span="24" class="infoActivitys">
        <div
          v-for="(item, index) in numPriceArr"
          :key="index"
          @click="checkFun(item, index)"
          :class="keyNum !== index ? 'numPriceDesBtn' : 'numPriceDesBtn active'"
        >
          <p>￥{{ item.price }}</p>
          <p>{{ item.num }}{{ goodsinfo.unit }}起</p>
        </div>
      </el-col>
    </el-row>
    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <!-- :max="Number(goodsinfo.quantity)" -->
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum"
          :max="
            goodsinfo.isLimit == 0
              ? Number(goodsinfo.quantity)
              : Number(goodsinfo.quantity) >
                Number(goodsinfo.purchaseLimitationNum)
              ? Number(goodsinfo.purchaseLimitationNum)
              : Number(goodsinfo.quantity)
          "
          :step="goodsinfo.minOrderNum"
          step-strictly
        ></el-input-number>
        <!-- 
          purchaseLimitationEndTime  限购截止日期
          purchaseLimitationNum  限购数量
          isLimit 是否限购 0否 1是
         -->
        <span v-if="goodsinfo.isLimit == 1">
          <span class="desPro" v-if="goodsinfo.purchaseLimitationNum > 0"
            >*截止{{
              goodsinfo.purchaseLimitationEndTime.substring(0, 10)
            }}之前，您还可以购买{{ goodsinfo.purchaseLimitationNum }}盒</span
          >
          <span class="desPro" v-else>已达购买上限，暂无法下单</span>
        </span>

        <span class="desPro">{{ NowPriceMSG }}</span>
      </el-col>
    </el-row>

    <div class="infoBtns" v-if="goodsinfo.isLimit == 1">
      <div :span="24">
        <span class="infoKey"></span>
        <button
          :disabled="
            Number(goodsinfo.purchaseLimitationNum) <
              Number(goodsinfo.minOrderNum) ||
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
          "
          :class="
            Number(goodsinfo.purchaseLimitationNum) <
              Number(goodsinfo.minOrderNum) ||
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
              ? 'grey shopBtn'
              : 'shopBtn'
          "
          @click="immediateBuy(goodsinfo)"
        >
          立即购买
        </button>
        <!-- <span v-if="goodsinfo.quantity< Number(goodsinfo.minOrderNum)">补货中</span>
          <span v-else>加入购物车</span> -->
        <button
          :disabled="
            Number(goodsinfo.purchaseLimitationNum) <
              Number(goodsinfo.minOrderNum) ||
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
          "
          :class="
            Number(goodsinfo.purchaseLimitationNum) <
              Number(goodsinfo.minOrderNum) ||
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
              ? 'grey addBtn'
              : 'addBtn'
          "
          @click="addCartFun(goodsinfo)"
        >
          加入购物车
        </button>
      </div>
    </div>
    <div class="infoBtns" v-else>
      <div :span="24">
        <span class="infoKey"></span>
        <button
          :disabled="goodsinfo.quantity < Number(goodsinfo.minOrderNum)"
          :class="
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
              ? 'grey shopBtn'
              : 'shopBtn'
          "
          @click="immediateBuy(goodsinfo)"
        >
          立即购买
        </button>
        <button
          :disabled="goodsinfo.quantity < Number(goodsinfo.minOrderNum)"
          :class="
            goodsinfo.quantity < Number(goodsinfo.minOrderNum)
              ? 'grey addBtn'
              : 'addBtn'
          "
          @click="addCartFun(goodsinfo)"
        >
          加入购物车
        </button>
      </div>
    </div>

    <!-- <el-col :span="24">
      <el-col :span="4"
        ><div class="grid-content bg-purple">
          服务承诺：<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">
          正品保障<i class="el-icon-circle-check" aria-hidden="true"></i></div
      ></el-col>
      <el-col :span="8"
        ><div class="grid-content bg-purple">
          满1000免运费【新疆除外】<i
            class="el-icon-circle-check"
            aria-hidden="true"
          ></i></div
      ></el-col>
      <el-col :span="4"
        ><div class="grid-content bg-purple-light">药监认证</div></el-col
      >
    </el-col> -->

    <!-- 多店铺弹窗 -->
    <el-dialog id="StoreBox" title="勾选店铺" :visible.sync="StoreShow">
      <el-col :span="24" class="selectedAllBox">
        <el-col :span="19">
          <label>
            <input
              type="checkbox"
              :checked="selectedAll"
              @change="AllSelectedFun()"
            />
            全选
          </label>
        </el-col>
      </el-col>
      <div class="MembersBox">
        <el-col
          class="LineBox"
          :span="24"
          v-for="(item, index) in StoreList"
          :key="index"
          @click="jumpPath(index)"
        >
          <el-col :span="19">
            <label @change="handleCheckChange(item)">
              <input type="checkbox" :checked="item.flag" />
              {{ item.enterpriseName }}
              <span class="unauthorizedBtn Btnbox">正常</span>
              <!-- <span class="lockBtn Btnbox" v-if="item.disableStatus==1">锁定</span>
                <span class="certifiedBtn Btnbox" v-else-if="item.auditStatus==4">正常</span>
                <span class="pastBtn Btnbox" v-else-if="item.auditStatus==6">资质过期</span>
                <span class="unauthorizedBtn Btnbox" v-else>未认证</span> -->
            </label>
          </el-col>
          <el-col :span="5" class="counterBox">
            <div class="counter">
              <InputNumbers :item="goodsinfo" :StoreItem="item"></InputNumbers>
            </div>
          </el-col>
        </el-col>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AddStoreFun(goodsinfo)"
          >加入购物车</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.Btnbox {
  width: 60px;
  text-align: center;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;

  font-size: 12px;
  border-style: solid;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-block;
}
.lockBtn {
  background-color: #999999;
  border-color: #999999;
  border-width: 1px;
  color: #fff;
}
.pastBtn {
  background-color: #8f8e92;
  border-color: #8f8e92;
  border-width: 1px;
  color: #fff;
}
.unauthorizedBtn {
  background-color: #ff6368;
  border-color: #ff6368;
  border-width: 1px;
  color: #fff;
}
.counterBox {
  position: relative;
  .counter {
    position: absolute;
    top: -5px;
  }
}
#StoreBox {
  .selectedAllBox {
    padding-bottom: 10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
  }
  .MembersBox {
    max-height: 400px;
    overflow-y: scroll;
    float: left;
    width: 100%;
  }

  ::-webkit-scrollbar {
    // width:3px;
    width: 8px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .LineBox {
    padding-bottom: 10px;
    margin-bottom: 6px;
    border-bottom: 1px dotted #ccc;
    label {
      line-height: 20px;
      padding-top: 5px;
    }
  }
  .el-dialog {
    background: url("../../assets/alertBg.png") no-repeat, #fff;
    background-size: 100% auto;
    padding-top: 70px;
    border-radius: 10px;
    .el-dialog__header {
      padding: 10px 20px 0px;
    }
    .el-dialog__body {
      padding: 10px 20px;
    }
    .el-dialog__close {
      display: none;
    }
    .dialog-footer {
      .el-button {
        margin: 0 auto !important;
        display: block !important;
        background: #ff4800 !important;
        border-radius: 30px !important;
        border: 1px solid #ff4800 !important;
        padding: 10px 50px;
      }
    }
  }
}
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName {
      font-weight: bolder;
      color: #333;
    }
    .tag {
      width: 42px;
      text-align: center;
      height: 20px;
      // padding: 0 5px;
      line-height: 19px;
      margin-top: 2px;
      border-width: 1px;
      font-size: 14px;
      border-style: solid;
      box-sizing: border-box;
      white-space: nowrap;
      display: inline-block;
      margin-right: 3px;
      &.titColor {
        color: #fff;
      }
      &.radiusBox {
        border-radius: 50%;
      }
      &.bgRed {
        background-color: #dd1f1f;
        border-color: #dd1f1f;
      }
      &.bgBlue {
        background-color: #409eff;
        border-color: #409eff;
      }
      &.bgGreen {
        background-color: #52c41a;
        border-color: #52c41a;
      }
      &.bgOrange {
        background-color: #ff7a45;
        border-color: #ff7a45;
      }
    }
  }
  .infoMsg {
    color: #666;
    font-size: 14px;
    line-height: 24px;
    margin-top: 5px;
  }

  .downBtn {
    cursor: pointer;
    background: #0ea0ff;
    color: #fff;
    padding: 2px 5px;
    &:hover {
      background: #ff3333;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    .desPro {
      margin-left: 15px;
      color: #fa7816ff;
      font-size: 13px;
    }
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 900;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #ff3333;
        color: #fff;
        margin-left: 20px;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.shopBtn {
        background: #ffcccc;
        color: #ff3333;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey {
        background: #ababb0;
        color: #fff;
        border: 1px solid #ababb0;
      }
    }
  }
  .CouponBox {
    padding: 0px 10px 5px;
    margin-bottom: 10px;
    color: #ff3333;
    div {
      float: left;
      width: 100%;
      .infoKey {
        width: 86px;
        text-align: left;
        display: inline-block;
        color: #333;
        font-size: 14px;
      }
      .conponMsg {
        font-size: 14px;
        padding: 2px 5px;
        border: 1px solid #ff3333;
        border-radius: 4px;
        margin-right: 10px;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          background: #f6f6f6;
          border: 2px solid #ff3333;
        }
      }
    }
  }

  // 重置优惠券组件样式，提高权重
  /deep/.coupon {
    width: 100%;
  }
  /deep/.el-drawer {
    width: 420px !important;
    padding: 0 20px 20px 20px;
  }
  .headTit {
    margin-top: 15px;
    line-height: 40px;
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }
  .conMain {
    max-height: 830px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    div {
      float: left;
      width: 50%;
      margin-bottom: 15px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    // .el-col {
    //   margin-bottom: 15px;
    // }
  }
  .UlBoxs {
    line-height: 20px;
    margin-bottom: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .el-divider--vertical {
      margin: 3px 8px;
      background: #333333ff;
    }
    .liBox {
      text-align: center;
      color: #ff3333;
      line-height: 20px;
      font-size: 16px;
      font-weight: bold;
      .price {
        font-family: smallYuan;
      }
      span.infoKey {
        text-align: left;
        display: inline-block;
        color: #666;
        font-size: 14px;
      }
      span.infoKeyPrice {
        text-align: left;
        display: inline-block;
        color: #333;
        font-size: 14px;
      }
      del {
        padding-left: 5px;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .infoBox {
    background: #fff8f3;
    padding: 20px 10px 10px 10px;
    font-size: 14px;
    color: #999;
    margin-bottom: 25px;

    del {
      padding-left: 5px;
      font-size: 12px;
      color: #333;
      text-decoration: line-through;
    }
    .el-col {
      margin-bottom: 25px;
      &.infoActivitys {
        margin-bottom: 0px;
        span.infoKey {
          width: 86px;
          line-height: 24px;
          text-align: center;
          display: inline-block;
          color: #333;
          font-size: 15px;
          font-weight: bold;
        }
      }
      &.infoActivity {
        margin-bottom: 0px;
        .ActivityDesBox {
          width: calc(100% - 86px);
          float: right;
        }
        .noMsg {
          height: 0;
        }
        span.infoKey {
          width: 86px;
          line-height: 24px;
          text-align: center;
          display: inline-block;
          color: #333;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    .hotProductTagBox {
      margin-bottom: 15px;
    }
    .hotProductTag {
      cursor: pointer;
      margin: 0;
      border-radius: 20px;
      background: #f43c38;
      color: #fff;
      padding: 5px 15px;
      font-size: 15px;
      text-align: center;
      b {
        display: inline-block;
        width: 15px;
        height: 15px;
        position: relative;
        img {
          display: inline-block;
          position: absolute;
          top: 2px;
          left: 0;
          width: 15px;
          height: 15px;
        }
      }
    }
    span.price {
      color: #ff3333;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }

    span.infoKey {
      width: 86px;
      text-align: left;
      display: inline-block;
      color: #333;
      font-size: 14px;
    }
    span.infoTag {
      display: inline-block;
      border: 1px solid #f43c38;
      color: #ff3333;
      background: #f43c3817;
      border-radius: 5px;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 1px 10px;
      margin: 0 10px;
      cursor: pointer;
    }
    span.infoDes {
      display: inline-block;
      color: #666;
      margin-bottom: 6px;
      line-height: 24px;
    }
    .numPriceDes {
      width: calc(100% - 176px);
      float: right;
      color: #666;
      letter-spacing: 1px;
      line-height: 24px;
      b {
        text-align: center;
        color: #ff3333;
        font-size: 14px;
      }
    }
  }
  .infoActivitysBox {
    padding: 0px 10px;
    margin-bottom: 25px;
    .infoKey {
      line-height: 24px;
      text-align: center;
      display: inline-block;
      color: #333;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .infoActivitys {
      .numPriceDesBtn {
        text-align: center;
        float: left;
        padding: 3px 28px 5px;
        border: 1.5px solid #e6e6e6ff;
        background: #f5f5f5ff;
        border-radius: 5px;
        margin-right: 8px;
        cursor: pointer;
        &.active {
          border: 1.5px solid #ff3333ff;
          background: #fee6e2ff;
          p:nth-child(2) {
            color: #ff3333ff;
            font-size: 12px;
          }
        }
        p {
          line-height: 16px;
          &:nth-child(1) {
            color: #ff3333ff;
            font-size: 14px;
            font-weight: bold;
            &::first-letter {
              font-size: 80%;
              font-weight: bolder;
            }
          }
          &:nth-child(2) {
            color: #666;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
<script>
const InputNumbers = () => import("components/common/InputNumbers.vue");
const GetCouponItem = () => import("components/common/GetCouponItem.vue");
import { AddCart, getByGoodsId } from "api/product.js";
import { mapState, mapMutations, mapGetters } from "vuex";
import { GetMemberStore } from "api/member.js";
import jsPDF from "jspdf";

export default {
  name: "GoodsInfo",
  data() {
    return {
      keyNum: -1,
      num: 0,
      promotionMap: [],
      // 领取优惠券参数
      drawer: false,
      //  佣金热销标签显示
      ByGoodsFlag: false,
      ByGoodsTag: "",
      ByGoodsId: "",

      selectedAll: false,
      StoreShow: false,
      StoreList: [],
      checkedIds: [], // 初始选中的值
      numPriceArr: [],
      priceMsg: "",
      NowPriceMSG: "",
      loading: false,
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {
    GetCouponItem,
    InputNumbers,
  },
  computed: {
    //计算属性可实时更新购物车数量
    CarGoodsNum() {
      return this.$store.getters.CarGoodsNum;
    },
    ...mapState({
      goodsnum: (state) => state.carGoodsNum,
    }),
  },
  methods: {
    checkFun(item, index) {
      this.keyNum = index;
      // if(index==this.keyNum){
      //   this.keyNum=-1
      // }else{
      //   this.keyNum=i
      // }
      this.priceMsg = item.price;
      this.num = item.num;
      this.NowPriceMSG =
        "预计省" +
        (
          (Number(this.goodsinfo.memberPrice) - Number(this.priceMsg)) *
          this.num
        ).toFixed(2) +
        "元";
    },
    // 跳转热销商品列表
    hotProductFun() {
      // alert(this.ByGoodsId)
      localStorage.setItem("ByGoodsTag", this.ByGoodsTag);
      localStorage.setItem("ByGoodsId", this.ByGoodsId);
      this.$router.push({
        name: "ProductType",
        query: { type: 12, ByGoodsTag: this.ByGoodsId },
      });
    },
    JumpONE_BUYFun(promotionId) {
      //  this.goodsinfo.promotionMap.promotionId
      this.$router.push({
        name: "ONEBUYDetail",
        query: { productId: this.goodsinfo.id, promotionId: promotionId },
      });
    },
    // 跳限时拼团详情页
    JumpFlashGroupFun(promotionId) {
      this.$router.push({
        name: "FlashGroupDetail",
        query: { promotionId: promotionId },
      });
    },
    // 跳超级秒杀详情页
    JumpFlashSaleFun(promotionId) {
      this.$router.push({
        name: "ActivityModuleDetail",
        query: { id: promotionId, ActivityType: 2 },
      });
    },
    handleClose(done) {
      done();
    },
    getImageDataUri(image) {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      return canvas.toDataURL("image/jpeg");
    },
    async getRotatedImageData(image) {
      try {
        // 创建一个 Promise，确保图片加载完成后再执行后续操作
        const loadImagePromise = new Promise((resolve) => {
          const img = new Image();
          img.crossOrigin = "anonymous"; // 设置图片为匿名 CORS 请求
          img.onload = () => resolve(img);
          img.onerror = () => {
            // alert("src");
            console.log("srcError")
          };
          img.src = image;
        });

        // 等待图片加载完成
        const loadedImage = await loadImagePromise;

        // 如果图片宽大于高，旋转成纵向
        const shouldRotate = loadedImage.width > loadedImage.height;

        // 计算图片的旋转角度
        const rotationAngle = shouldRotate ? 90 : 0;

        // 创建一个 Canvas 元素用于旋转图片
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // 如果需要旋转，设置 Canvas 尺寸
        if (shouldRotate) {
          canvas.width = loadedImage.height;
          canvas.height = loadedImage.width;
        } else {
          canvas.width = loadedImage.width;
          canvas.height = loadedImage.height;
        }

        // 在 Canvas 上绘制旋转后的图片
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate(rotationAngle * (Math.PI / 180));
        context.drawImage(
          loadedImage,
          -loadedImage.width / 2,
          -loadedImage.height / 2
        );

        // 将旋转后的图片转换为 Base64 数据 URI，保持原始宽高比例
        const rotatedImageDataUri = canvas.toDataURL("image/jpeg", 1.0);

        return { rotatedImageDataUri, rotationAngle };
      } catch (error) {
        console.error("Error rotating image:", error);
        return null;
      }
    },
    async Download() {

      // console.log(this.goodsinfo)
      try {
        this.loading = true;
        const pdf = new jsPDF();
        const drugCertificatePic = this.goodsinfo.drugCertificatePic || "";
        const imagePaths = drugCertificatePic.split(",");

        // 定义每页的高度和宽度（根据需要调整）
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;

        if (drugCertificatePic === "") {
          this.loading = false;
          return;
        }
        // 循环遍历图片列表
        for (let index = 0; index < imagePaths.length; index++) {
          const imagePath = imagePaths[index];

          // 获取图片的旋转后的数据 URI 和旋转角度
          const { rotatedImageDataUri } = await this.getRotatedImageData(
            imagePath.replace("https", "http")
          );

          // 添加旋转后的图片到 PDF，保持原始宽高比例
          pdf.addImage(
            rotatedImageDataUri,
            "JPEG",
            0,
            0,
            pageWidth,
            pageHeight,
            null,
            "FAST"
          );
          // 如果不是最后一页，添加新的一页
          if (index !== imagePaths.length - 1) {
            pdf.addPage();
          }
        }

        // 保存或下载 PDF 文件
        pdf.save("output.pdf");
        this.loading = false;
      } catch (error) {
        console.error("Error generating PDF:", error);
        this.loading = false;
      }
    },
    async showload() {
      var printWindow = window.open("", "_blank");
      printWindow.document.open();
      const drugCertificatePic = this.goodsinfo.drugCertificatePic || "";
      const imagePaths = drugCertificatePic.split(",");
      printWindow.document.write(
        "<html><head><title>首营资料</title></head><body style='width:100%;height:100%;'>"
      );
      for (let i = 0; i < imagePaths.length; i++) {
        printWindow.document.write(
          "<img style='width: 100%;' src='" + imagePaths[i] + "' alt=''/>"
        );
      }
      printWindow.document.write("</body></html>");
      printWindow.document.close();
    },
    ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
    ...mapGetters({
      typeQuantity: "typeQuantity",
    }),
    handleChange() {
      // value
      // console.log(value)
      this.$nextTick(() => {
        // console.log('dangqian',this.num)
        if (this.numPriceArr.length == 0) {
          return true;
        }
        if (this.num < this.numPriceArr[this.numPriceArr.length - 1].num) {
          this.priceMsg =
            Number(this.numPriceArr[this.numPriceArr.length - 1].price).toFixed(
              2
            ) + "起";
          this.keyNum = -1;
          console.log("this.goodsinfo.memberPrice", this.goodsinfo.memberPrice);

          this.NowPriceMSG = "";
        } else {
          for (var index = 0; index < this.numPriceArr.length; index++) {
            if (this.numPriceArr[index].num <= this.num) {
              this.keyNum = index;
              this.priceMsg = this.numPriceArr[index].price;
              console.log(
                "this.goodsinfo.memberPrice",
                this.goodsinfo.memberPrice
              );
              this.NowPriceMSG =
                "预计省" +
                (
                  (Number(this.goodsinfo.memberPrice) - Number(this.priceMsg)) *
                  this.num
                ).toFixed(2) +
                "元";
              return true;
            }
          }
        }
      });
    },

    AddStoreFun(data) {
      if (this.checkedIds.length < 1) {
        this.$message({
          message: "最少勾选一个门店",
          type: "warning",
        });
        return false;
      }
      this.StoreShow = false;
      //  多个店铺，加购物
      // 获取选中值。将商品添加到选中店铺
      const addcartDate = [];
      this.StoreList.forEach((item) => {
        if (item.flag) {
          addcartDate.push({
            isRecentExpiration: data.isRecentExpiration,
            quantity: item.num,
            productId: data.id == -1 ? data.productId : data.id,
            goodNum: data.goodNum,
            memberStoreId: item.id,
          });
        }
      });
      console.log(addcartDate);
      // 加入购物车接口
      AddCart(addcartDate).then((data) => {
        // this.$router.push({ name: "Cart", query: { id: id } });
        if (data.data.code == 200) {
          // 提示信息
          this.$notify({
            title: "加入购物车",
            message: "成功",
            type: "success",
            duration: 1000,
          });
          this.setCarGoodsNum(this.num);
        } else {
          this.$notify({
            title: "提示信息",
            message: data.data.msg,
            type: "error",
            duration: 1000,
          });
        }
      });
    },
    // 全选
    AllSelectedFun() {
      this.selectedAll = !this.selectedAll;
      let checkedIds = [];
      this.StoreList.forEach((item) => {
        checkedIds.push(item.id);
        item.flag = this.selectedAll ? true : false;
      });
      this.checkedIds = this.selectedAll ? checkedIds : [];
    },
    handleCheckChange(item) {
      item.flag = !item.flag;
      var bStop = true;
      for (var i = 0; i < this.StoreList.length; i++) {
        if (!this.StoreList[i].flag) {
          bStop = false;
          break;
        }
      }
      this.selectedAll = bStop;
      let checkedIds = [];
      this.StoreList.forEach((item) => {
        if (item.flag) {
          checkedIds.push(item.id);
        }
      });
      this.checkedIds = checkedIds;
      console.log(this.checkedIds);
    },

    addCartFun(data) {
      if (this.goodsinfo.price === "***") {
        this.$message({
          message:
            "请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息",
          type: "warning",
        });
        return false;
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}

      // this.goodsinfo.isLimit==1  判断限购

      // 如果一个店铺则直接加购物，多个店铺则跳出弹窗显示店铺
      GetMemberStore().then((res) => {
        //  只返回正常店铺数据     item.auditStatus==4   正常    item.disableStatus==1  锁定
        this.StoreList = res.data.data.filter((item) => {
          return item.auditStatus == 4 && item.disableStatus != 1;
        });

        if (this.StoreList.length > 1) {
          this.StoreShow = true;
          // this.StoreList=res.data.data
          this.StoreList.forEach((item) => {
            item.flag = false;
            item.num = this.goodsinfo.minOrderNum;
          });
        } else {
          this.StoreShow = false;
          //  一个店铺，直接加购物
          // this.StoreList=res.data.data
          const addcartDate = [];
          addcartDate.push({
            isRecentExpiration: data.isRecentExpiration,
            quantity: this.num,
            productId: data.id,
            goodNum: data.goodNum,
            memberStoreId: this.StoreList[0].id,
          });
          // 加入购物车接口
          AddCart(addcartDate).then((data) => {
            // this.$router.push({ name: "Cart", query: { id: id } });
            if (data.data.code == 200) {
              // 提示信息
              this.$notify({
                title: "加入购物车",
                message: "成功",
                type: "success",
                duration: 1000,
              });
              let count = this.num;
              this.setCarGoodsNum(count);
            } else {
              this.$notify({
                title: "提示信息",
                message: data.data.msg,
                type: "error",
                duration: 1000,
              });
            }
          });
        }
        console.log("是否多店铺" + this.StoreShow);
      });
    },
    immediateBuy: function (data) {
      if (this.goodsinfo.price === "***") {
        this.$message({
          message: "未认证用户不能购买",
          type: "warning",
        });
        return false;
      }
      const addcartDate = [];
      addcartDate.push({
        isRecentExpiration: data.isRecentExpiration,
        quantity: this.num,
        productId: data.id,
        goodNum: data.goodNum,
      });
      // 加入购物车接口
      AddCart(addcartDate).then((data) => {
        if (data.data.code == 200) {
          // 提示信息
          this.$notify({
            title: "加入购物车",
            message: "成功",
            type: "success",
            duration: 1000,
          });

          let count = this.num;
          this.setCarGoodsNum(count);
          this.$router.push({ name: "Cart" });
        } else {
          this.$notify({
            title: "提示信息",
            message: data.data.msg,
            type: "error",
            duration: 1000,
          });
        }
      });
    },
  },
  mounted() {},
  watch: {
    goodsinfo: function () {},
  },
  created() {
    if (this.goodsinfo.numPrice && this.goodsinfo.numPrice != "") {
      this.numPriceArr = JSON.parse(this.goodsinfo.numPrice);
      // 倒序排序
      this.numPriceArr.sort(function (a, b) {
        return b.num - a.num;
      });
      console.log(this.numPriceArr);
      this.priceMsg = Number(this.numPriceArr[0].price).toFixed(2) + "起";
      if (
        this.numPriceArr[this.numPriceArr.length - 1].num ==
        this.goodsinfo.minOrderNum
      ) {
        this.keyNum = this.numPriceArr.length - 1;
        this.priceMsg = this.numPriceArr[this.numPriceArr.length - 1].price;
        this.NowPriceMSG =
          "预计省" +
          (
            (Number(this.goodsinfo.memberPrice) -
              Number(this.numPriceArr[this.numPriceArr.length - 1].price)) *
            this.goodsinfo.minOrderNum
          ).toFixed(2) +
          "元";
      }
    }
    this.num = this.goodsinfo.minOrderNum;
    // this.promotionMap=this.goodsinfo.promotionMap
    let arr = [];
    this.goodsinfo.promotionMap.forEach((item) => {
      if (item.type != "ONE_BUY促销活动") {
        if (item.type != "特价") {
          arr.push(item);
        }
      }
    });
    this.promotionMap = arr;
    // 传入goodsId获取是否设置 佣金热销标签
    // console.log('aaaa1111111',this.goodsinfo)
    getByGoodsId(this.goodsinfo.id).then((res) => {
      // alert(789)
      console.log(res, "123456");
      if (res.status === 400) {
        this.ByGoodsFlag = false;
      } else {
        this.ByGoodsFlag = true;
        this.ByGoodsTag = res.data.data.categroyName;
        this.ByGoodsId = res.data.data.categroyId;
      }
    });
  },
};
</script>
